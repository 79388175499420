import { Pipe, PipeTransform } from '@angular/core';
import { FeatureService } from '../startup/feature.service';

@Pipe({
  name: 'featureFlag',
  pure: true,
  standalone: true,
})
export class FeatureFlagPipe implements PipeTransform {

  constructor(private featureService: FeatureService) {}

  transform(name: string): boolean {
    return this.featureService.isFeatureActivated(name);
  }
}
